<template>

  <b-card-code>
    <confirm-delete ref="modal" :msg="convertMsg" :title="titleMsg" @on-submit="convertUser" />
    <b-col v-if="userAddForm.role_id === 5 && userDetails.isActive && isLoaded" md="12">
      <b-row>
        <div class="text-right w-100">
          <b-button
            variant="primary"
            class="ml-auto openAgentModal"
            @click="openModal(userId,userAddForm.first_name)"
          >
            <feather-icon
              icon="EditIcon"
              class="d-block d-md-none"
            />
            <span class="font-weight-bold d-none d-md-block">Change to Agent</span>
          </b-button>
        </div>
      </b-row>
    </b-col>
    <b-col v-if="(userDetails.convertToAgentDate || convertedDate) && isLoaded" md="12">
      <b-row>
        <div class="text-right w-100">
          <span class="font-weight-bold d-none d-md-block">Converted Agent On : {{ (userDetails.convertToAgentDate || convertedDate) | formatDate }} </span>
        </div>
      </b-row>
    </b-col>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', userAddForm)">
        <b-row col>
          <b-col md="6">
            <b-form-group
              label="First Name"
              label-for="mc-first-name"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required|alpha|max:60|min:3"
              >
                <b-form-input
                  v-model="userAddForm.first_name"
                  class="first_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="First Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Last Name"
              label-for="mc-last-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required|max:60|alpha|min:3"
              >
                <b-form-input
                  v-model="userAddForm.last_name"
                  class="last_name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Last Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Customer Type"
              rules="required"
            >
              <b-form-group
                label="Customer Type"
                label-for="mc-select-role"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="userAddForm.role_id"
                  :disabled="isLoaded"
                  placeholder="Customer Type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customerRole"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Gender"
            >
              <b-form-group
                label="Gender"
                label-for="mc-select-gender"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="userAddForm.gender"
                  placeholder="Select Gender"
                  :state="errors.length > 0 ? false:null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genderOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="mc-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email|max:254"
              >
                <b-form-input
                  v-model="userAddForm.email"
                  class="email"
                  :readonly="isLoaded"
                  :state="errors.length > 0 ? false:null"
                  type="email"
                  placeholder="Your Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="!isLoaded" md="6">
            <b-form-group
              label="Password"
              label-for="mc-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8|max:30|password|"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">

                  <b-form-input
                    v-model="userAddForm.password"
                    class="password"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Your Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer togglePasswordNew"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="!isLoaded" md="6">
            <b-form-group
              label="Confirm Password"
              label-for="mc-confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password Confirm"
                rules="required|min:8|max:30|confirmed:Password"
              >
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">

                  <b-form-input
                    v-model="userAddForm.confirm_password"
                    class="confirm_password"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldTypeConfirm"
                    placeholder="Confirm Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconConfirm"
                      class="cursor-pointer togglePasswordConfirm"
                      @click="togglePasswordConfirm"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Country"
              rules="required"
            >
              <b-form-group
                label="Country"
                label-for="mc-select-role"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="userAddForm.country_id"
                  placeholder="Select Country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryList"
                  label="country"
                  :reduce="val => val.id"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Mobile Number"
              label-for="mc-first-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Mobile Number"
                rules="positive|min:8|max:10"
              >
                <b-form-input
                  v-model="userAddForm.phone_no"
                  class="phone_No"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Mobile Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Send Email & IsActive check box -->
          <b-col md="6">
            <b-form-group v-if="!isLoaded"
                          label="Send Email"
                          label-for="mc-sendemail"
            >
              <b-form-checkbox
                v-model="userAddForm.send_email"
              />
            </b-form-group>
            <b-form-group v-if="isLoaded"
                          label="Is Active"
                          label-for="mc-isactive"
            >
              <b-form-checkbox
                v-model="userAddForm.is_active"
              />
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="demo-inline-spacing">
            <b-button
              :disabled="loading"
              variant="primary"
              type="submit"
            >
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              variant="secondary"
              :to="{name: ((checkLoginRole() === RoleEnum.SuperAdmin) || checkAccess.view)?'customers-customers-list':'dashboard'}"
            >
              Cancel
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
// import {
//   required, email, confirmed, password, alpha, positive
// } from '@validations'
import {
  required, email, confirmed, alpha, password, positive
} from '@core/utils/validations/validations'
import vSelect from 'vue-select'
import {
  customerRole, genderOptions, resConditionCheck, RoleEnum
} from '@/helpers/constant'
import errorResponseHandler from '@/services/errorHandler'
import checkLoginRole from '@/helpers/checkLoginRole'
import accessRightCheck from '@/helpers/accessRightCheck'
import CountryService from '../../../../services/country/country.service'
import CustomerAdminService from '../../../../services/customer-admin/customerAdmin.service'
import ConfirmDelete from "../../../confirm-delete/ConfirmDelete.vue"

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    ConfirmDelete
  },
  props: {
    userDetails: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      userAddForm: {
        email: this.userDetails?.email || "",
        first_name: this.userDetails?.firstName || "",
        last_name: this.userDetails?.lastName || "",
        role_id: this.userDetails?.roleId || "",
        gender: this.userDetails?.gender || "",
        password: '',
        confirm_password: '',
        send_email: false,
        is_active: this.userDetails?.isActive || false,
        country_id: this.userDetails?.countryId || "",
        phone_no: this.userDetails?.phoneNo || "",
      },
      required,
      password,
      email,
      checkAccess: {},
      confirmed,
      alpha,
      loading: false,
      customerRole,
      genderOptions,
      positive,
      RoleEnum,
      checkLoginRole,
      countryList: [],
      passwordFieldTypeConfirm: 'password',
      passwordFieldTypeNew: 'password',
      userId: this.$route.params.id ? this.$route.params.id : '',
      convertMsg: '',
      titleMsg: '',
      convertedDate: ''
    }
  },

  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name)
    await this.getCountryList()
  },
  methods: {
    togglePasswordConfirm() {
      this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      let valid = null
      await this.$refs.simpleRules.validate().then(success => {
        if (success) {
          valid = true
        } else {
          valid = false
        }
      })
      return valid
    },
    getCountryList() {
      CountryService.getCountryList()
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.countryList = res.data.data
          }
        }).catch(error => {
          errorResponseHandler(error)
        })
    },
    openModal(userId, userName) {
      this.titleMsg = `Convert Customer to Agent`
      this.convertMsg = `Are you sure you want to convert ${userName} to agent ?`
      this.$refs.modal.show()
    },
    convertUser(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.modal.loading = true
      if (this.userId) {
        CustomerAdminService.customerToAgent(this.userId).then(res => {
          if (resConditionCheck(res.status)) {
            this.$refs.modal.loading = false
            this.$refs.modal.toggleModal = false
            this.userAddForm.role_id = res.data.data.roleId
            this.convertedDate = res.data.data.convertToAgentDate
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          this.$refs.modal.loading = false
          this.$refs.modal.toggleModal = false
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
