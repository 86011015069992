var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('confirm-delete',{ref:"modal",attrs:{"msg":_vm.convertMsg,"title":_vm.titleMsg},on:{"on-submit":_vm.convertUser}}),(_vm.userAddForm.role_id === 5 && _vm.userDetails.isActive && _vm.isLoaded)?_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('div',{staticClass:"text-right w-100"},[_c('b-button',{staticClass:"ml-auto openAgentModal",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.openModal(_vm.userId,_vm.userAddForm.first_name)}}},[_c('feather-icon',{staticClass:"d-block d-md-none",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"font-weight-bold d-none d-md-block"},[_vm._v("Change to Agent")])],1)],1)])],1):_vm._e(),((_vm.userDetails.convertToAgentDate || _vm.convertedDate) && _vm.isLoaded)?_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[_c('div',{staticClass:"text-right w-100"},[_c('span',{staticClass:"font-weight-bold d-none d-md-block"},[_vm._v("Converted Agent On : "+_vm._s(_vm._f("formatDate")((_vm.userDetails.convertToAgentDate || _vm.convertedDate)))+" ")])])])],1):_vm._e(),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('on-Submit', _vm.userAddForm)}}},[_c('b-row',{attrs:{"col":""}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"mc-first-name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|alpha|max:60|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"first_name",attrs:{"state":errors.length > 0 ? false:null,"placeholder":"First Name"},model:{value:(_vm.userAddForm.first_name),callback:function ($$v) {_vm.$set(_vm.userAddForm, "first_name", $$v)},expression:"userAddForm.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"mc-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|max:60|alpha|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"last_name",attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Last Name"},model:{value:(_vm.userAddForm.last_name),callback:function ($$v) {_vm.$set(_vm.userAddForm, "last_name", $$v)},expression:"userAddForm.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Customer Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Customer Type","label-for":"mc-select-role","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"disabled":_vm.isLoaded,"placeholder":"Customer Type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.customerRole,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.userAddForm.role_id),callback:function ($$v) {_vm.$set(_vm.userAddForm, "role_id", $$v)},expression:"userAddForm.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Gender","label-for":"mc-select-gender","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"placeholder":"Select Gender","state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.genderOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.userAddForm.gender),callback:function ($$v) {_vm.$set(_vm.userAddForm, "gender", $$v)},expression:"userAddForm.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"mc-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email|max:254"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"email",attrs:{"readonly":_vm.isLoaded,"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Your Email"},model:{value:(_vm.userAddForm.email),callback:function ($$v) {_vm.$set(_vm.userAddForm, "email", $$v)},expression:"userAddForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.isLoaded)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"mc-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|min:8|max:30|password|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"password",attrs:{"type":_vm.passwordFieldTypeNew,"state":errors.length > 0 ? false:null,"placeholder":"Your Password"},model:{value:(_vm.userAddForm.password),callback:function ($$v) {_vm.$set(_vm.userAddForm, "password", $$v)},expression:"userAddForm.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer togglePasswordNew",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3569399741)})],1)],1):_vm._e(),(!_vm.isLoaded)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"mc-confirm-password"}},[_c('validation-provider',{attrs:{"name":"Password Confirm","rules":"required|min:8|max:30|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"confirm_password",attrs:{"state":errors.length > 0 ? false:null,"type":_vm.passwordFieldTypeConfirm,"placeholder":"Confirm Password"},model:{value:(_vm.userAddForm.confirm_password),callback:function ($$v) {_vm.$set(_vm.userAddForm, "confirm_password", $$v)},expression:"userAddForm.confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer togglePasswordConfirm",attrs:{"icon":_vm.passwordToggleIconConfirm},on:{"click":_vm.togglePasswordConfirm}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1367760510)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Country","label-for":"mc-select-role","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"placeholder":"Select Country","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryList,"label":"country","reduce":function (val) { return val.id; },"clearable":false},model:{value:(_vm.userAddForm.country_id),callback:function ($$v) {_vm.$set(_vm.userAddForm, "country_id", $$v)},expression:"userAddForm.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Mobile Number","label-for":"mc-first-name"}},[_c('validation-provider',{attrs:{"name":"Mobile Number","rules":"positive|min:8|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"phone_No",attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Mobile Number"},model:{value:(_vm.userAddForm.phone_no),callback:function ($$v) {_vm.$set(_vm.userAddForm, "phone_no", $$v)},expression:"userAddForm.phone_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[(!_vm.isLoaded)?_c('b-form-group',{attrs:{"label":"Send Email","label-for":"mc-sendemail"}},[_c('b-form-checkbox',{model:{value:(_vm.userAddForm.send_email),callback:function ($$v) {_vm.$set(_vm.userAddForm, "send_email", $$v)},expression:"userAddForm.send_email"}})],1):_vm._e(),(_vm.isLoaded)?_c('b-form-group',{attrs:{"label":"Is Active","label-for":"mc-isactive"}},[_c('b-form-checkbox',{model:{value:(_vm.userAddForm.is_active),callback:function ($$v) {_vm.$set(_vm.userAddForm, "is_active", $$v)},expression:"userAddForm.is_active"}})],1):_vm._e()],1),_c('b-col',{staticClass:"demo-inline-spacing",attrs:{"md":"12"}},[_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"primary","type":"submit"}},[_vm._v(" Submit "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()]),_c('b-button',{attrs:{"variant":"secondary","to":{name: ((_vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin) || _vm.checkAccess.view)?'customers-customers-list':'dashboard'}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }